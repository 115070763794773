<template>
  <div>
    <slot></slot>
  </div>
</template>
<script setup>
const iconStore = useIconStore()

await useAsyncData(
  'icons',
  async () => {
    const [icons] = await Promise.all([iconStore.fetchIcons()])
    return { icons }
  },
  { lazy: true }
)
</script>
